import './App.css';
import Clock from './components/Clock';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { getDownloadURL, listAll, ref, getMetadata} from "@firebase/storage";
import { storage } from './firebase'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Unity, useUnityContext } from "react-unity-webgl";

// Default theme
import '@splidejs/react-splide/css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Fragment } from 'react';

function App() {

 
  const { unityProvider, loadingProgression, isLoaded, unload } = useUnityContext({
    loaderUrl: "unitybuild/ZPELENDIGSPILBUILD.loader.js",
    dataUrl: "unitybuild/webgl.data",
    frameworkUrl: "unitybuild/build.framework.js",
    codeUrl: "unitybuild/build.wasm",
  });

  // textInput must be declared here so the ref can refer to it
  const startMenu = useRef(null);
  const bottomBar = useRef(null);
  const imageViewer = useRef(null);
  const audioPlayerRef = useRef(null);
  const [heightWidthArray, setHeightWidthArray] = useState([]);
  const [zIndexArray, setZIndexArray] = useState([]);
  const [loadImages, setLoadImages] = useState(true);
  const [gameActivated, setGameActivated] = useState(false);
  const [loadMusic, setLoadMusic] = useState(true);
  const [imagesData, setImageData] = useState([]);
  const [musicData, setMusicData] = useState([]);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [loadingImageViewer, setLoadingImageViewer] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState("No Audio");
  const [audioFile, setAudioFile] = useState(undefined);
  const [isDraggingDesktopIcon, setIsDraggingDesktopIcon] = useState(false); 
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const updateZIndexArray = (element) => {

        //var dataid = parseInt(element.getAttribute("dataid"));
        if(element.className==="photoItem" || element.className==="photoImage" || element.className==="photoLabel"
        || element.className==="iconImage" || element.className==="iconLabel") {
          return;
        }
        if(element.getAttribute("dataid")===null) {
          element = element.closest(".window");
        }
        var tempZIndexArray = zIndexArray;
        var tempObj = {};
        var allWindows = document.querySelectorAll(".window");
        //First we find the dataId match in the zindex array then pop it and unshift. So we are basically taking the element with the matching dataid and moving it to the top of the zindex array and setting all the zindexes to the position in the array
        for(let i = 0; i<tempZIndexArray.length; i++) {
          if(tempZIndexArray[i].dataId===element.getAttribute("dataid")) {
            if(i===0) {
              return
            }
            tempObj = tempZIndexArray[i];
            tempZIndexArray.splice(i,1);
            tempZIndexArray.unshift(tempObj);
          }
        } 
        setZIndexArray(tempZIndexArray);
        for(let i = 0; i<tempZIndexArray.length; i++) {
          for(let u = 0; u<allWindows.length; u++) {
            if(tempZIndexArray[i].dataId===allWindows[u].getAttribute("dataid")) {
              if(tempZIndexArray[i].dataId==='20') {
                allWindows[u].style.zIndex = 1002;
              } else {
                allWindows[u].style.zIndex = 1000-i;
              }
              
            }
          }
        } 
        



  }

  const openWindow = (name) => {
    const element = document.getElementsByName(name);
    if(element) {
      var arrayIndex = parseInt(element[0].getAttribute("dataid"));
      
      if(heightWidthArray[arrayIndex].first===true && arrayIndex!==4) {

        var hWidthArr = heightWidthArray;
        hWidthArr[arrayIndex].first=false;

        if(arrayIndex!==18) {
          element[0].style.width = window.innerWidth/2+"px"
          element[0].style.height = window.innerHeight/2+"px"
        }
        setHeightWidthArray(hWidthArr);
      }
      if(name==='billeder') {
        if(element[0].style.display === 'inline-table') {
          element[0].children[0].classList.add('blinkingAni');
          setTimeout(()=> {
            element[0].children[0].classList.remove('blinkingAni');
          },400);
        }else {
          moveWindowIfAnotherWindowAtSamePosition(element[0]);

          closeStartMenu();
          element[0].style.display = 'inline-table';
          element[0].classList.add('openWindowAni');
          setTimeout(()=> {
            element[0].classList.remove('openWindowAni');
          },100);
        }
      }
      else {
      if(element[0].style.display === 'block') {
        element[0].children[0].classList.add('blinkingAni');
        setTimeout(()=> {
          element[0].children[0].classList.remove('blinkingAni');
        },400);
      }
      else {
        if(name==="billedfremviser") {
          if(isMobile) {
            if(element[0].style.height!=="100%") {
              resizeImageViewerNotFromClick();
            }
            
          } else {
            moveWindowIfAnotherWindowAtSamePosition(element[0]);
          }
        } else if(name==="zpspil") {
          resizeGameWindow();
        } else {
          moveWindowIfAnotherWindowAtSamePosition(element[0]);
        }
        
/*         console.log(windowsThatAreOpen);
        if(fromWindow!==undefined) {
          if(fromWindow.style.top===element[0].style.top && fromWindow.style.left===element[0].style.left) {
            let topStr = fromWindow.style.top.toString();
            let topNumber = parseInt(topStr.replace(/[^0-9]/g,""));
            let leftStr = fromWindow.style.left.toString();
            let leftNumber = parseInt(leftStr.replace(/[^0-9]/g,""));
            console.log(topNumber);
            leftNumber+=2;
            topNumber+=2;
            console.log(topNumber);
            element[0].style.top = topNumber+"%";
            element[0].style.left = leftNumber+"%";
          }
        } */
        closeStartMenu();
        element[0].style.display = 'block';
        element[0].classList.add('openWindowAni');
        setTimeout(()=> {
          element[0].classList.remove('openWindowAni');
        },100);
      }

      

    }
    element[0].focus();
    setTimeout(()=> {
      updateZIndexArray(element[0]);
    },10);
    
  }
  }

  const moveWindowIfAnotherWindowAtSamePosition = (windowToMove) => {
        //Check if the window it is coming from is already occupying the same space as the new window.
        //Check if there is any window at the same position as the one we are opening a window to
        let windowsThatAreOpen = [];
        let allWindows = document.querySelectorAll(".window");
        for(let i = 0; i<allWindows.length; i++) {
          if(allWindows[i].style.display==="block" || allWindows[i].style.display==="inline-table") {
            windowsThatAreOpen.push(allWindows[i]);
          }
        }
        if(windowsThatAreOpen.length>0) {
          //Check if there is a window in the array with the same position
          for(let i = 0; i<windowsThatAreOpen.length; i++) {
            if(windowsThatAreOpen[i].style.top===windowToMove.style.top && windowsThatAreOpen[i].style.left===windowToMove.style.left) {
              
              //Check if it is a % number or px number
              if(windowsThatAreOpen[i].style.height==="100%") {
                return;
              }
              let topStr = windowsThatAreOpen[i].style.top.toString();
              let leftStr = windowsThatAreOpen[i].style.left.toString();
              if(topStr.includes("%")) {
                let topNumber = parseInt(topStr.replace(/[^0-9]/g,""));
                let leftNumber = parseInt(leftStr.replace(/[^0-9]/g,""));

                leftNumber+=2;
                topNumber+=2;

                windowToMove.style.top = topNumber+"%";
                windowToMove.style.left = leftNumber+"%";
              } else {
                let topNumber = parseInt(topStr.replace(/[^0-9]/g,""));
                let leftNumber = parseInt(leftStr.replace(/[^0-9]/g,""));

                leftNumber+=10;
                topNumber+=10;

                windowToMove.style.top = topNumber+"px";
                windowToMove.style.left = leftNumber+"px";
              }

              
            }
          }
          for(let i = 0; i<windowsThatAreOpen.length; i++) {
            if(windowsThatAreOpen[i].style.top===windowToMove.style.top && windowsThatAreOpen[i].style.left===windowToMove.style.left) {
              
              moveWindowIfAnotherWindowAtSamePosition(windowToMove);

              
            }
          }
          
        }
  }

  async function fetchImages() {
    try {
      const imagesRef = ref(storage,"Billeder");
      let tempArr = [...imagesData];
      await listAll(imagesRef).then((res) => {
        let promises = res.items.map((imageRef) => getDownloadURL(imageRef));
        Promise.all(promises).then((urls) => {
              urls.forEach((item, i) => {
                tempArr[i] = {src: item};
              })
    })
    }).catch((err)=> {
        console.log(err)
    })

    await listAll(imagesRef).then((res) => {
      let promises2 = res.items.map((imageRef) => getMetadata(imageRef));
      Promise.all(promises2).then((data) => {
        data.forEach((item, i) => {
          tempArr[i].name = item.name;
          setImageData(tempArr);
        })
  })
  }).catch((err)=> {
      console.log(err)
  })
    setLoadImages(false);
    }
    catch(err) {
      console.log(err.message);
    }
  }

  async function fetchAudio() {
    try {
      const musicRef = ref(storage,"Papirkurv/@Z'_d¤9&2s=");
      let tempArr = [...musicData];
      await listAll(musicRef).then((res) => {
        let promises = res.items.map((musicRef) => getDownloadURL(musicRef));
        Promise.all(promises).then((urls) => {
              urls.forEach((item, i) => {
                tempArr[i] = {src: item};
              })
    })
    }).catch((err)=> {
        console.log(err)
    })

    await listAll(musicRef).then((res) => {
      let promises2 = res.items.map((musicRef) => getMetadata(musicRef));
      Promise.all(promises2).then((data) => {
        data.forEach((item, i) => {
          tempArr[i].name = item.name;
          setMusicData(tempArr);
        })
  })
  }).catch((err)=> {
      console.log(err)
  })
    setLoadMusic(false);
    }
    catch(err) {
      console.log(err.message);
    }
  }


var tTime = 0
/*const handleDoubleClick  = (window, index=0) => {
  if (tTime === 0) {
    // set first click
    tTime = new Date().getTime();
} else {
    // compare first click to this click and see if they occurred within double click threshold
    if (((new Date().getTime()) - tTime) < 200) {
        // double click occurred
        openWindow(window);
        tTime = 0;
        if(window ==='billeder') {
          if(imagesData.length<=1){
            setLoadImages(true);
            fetchImages();
          }
          console.log('Images fetched!');
        }
        if(window ==='billedfremviser') {

              setTimeout(()=> {
                imageViewerGo(index);
              },100);
            
          
        }

    } else {
        // not a double click so set as a new first click
        tTime = new Date().getTime();
    }
}
}*/

const handleClick = (windowName, index=0, e=undefined) => {
  if(isDraggingDesktopIcon) {
    return
  }
  //Direct links NOT WINDOWS
  if(windowName==="købPlade") {
    window.open("https://www.beatdown.dk/kunstnere/zar-paulo/p/zp200-zar-paulo-elendig-software-lp","_blank");
    return
  }
  if(windowName==="nyhedsbrev") {
    window.open("https://thebankmusic.us14.list-manage.com/subscribe?u=f5b08f74f467a44feab8ab5b4&id=a0533bb8f4","_blank");
    return
  }
  if(windowName==="merch") {
    window.open("https://www.beatdown.dk/kunstnere/zar-paulo","_blank");
    return
  }
  if(windowName==="live") {
    window.open("https://tix.to/zarpaulo","_blank");
    return
  }

  //WINDOWS
  // double click occurred
  if(windowName ==='billeder') {
    if(imagesData.length<=1){
      setLoadImages(true);
      fetchImages();
    }
    openWindow(windowName);
  }
  else if (windowName === 'musik') {
    if(musicData.length<=1){
      setLoadMusic(true);
      fetchAudio();
    }
    openWindow(windowName);
  }
  else if(windowName ==='billedfremviser') {
    if(e!=undefined) {
      e.target.parentNode.style.cursor = 'progress';
      setLoadingImageViewer(true);
      openWindow(windowName);
    //e.target.parentNode.style.display = "block";
    //setOpenImageViewer(true);
        setTimeout(()=> {
          imageViewerGo(index, e.target.parentNode);
        },100);
      }
} else if(windowName === 'zpspil') {

  openWindow(windowName);
  setTimeout(()=> {
    setGameActivated(true);
  },100);

} else {
  openWindow(windowName);
}

}

const handlePlayAudio = (audio) => {
  setAudioFile(audio);
  audioPlayerRef.current.audio.current.play();
}

const handleStopAudio = () => {
  audioPlayerRef.current.audio.current.pause();
}

  const closeWindow = (name) => {
    const element = document.getElementsByName(name);
    if(element) {
      element[0].style.display = 'none';
    }
  }

  const updateImageViewer = () => {
    let index = imageViewer.current.splide.index;
    if(index === imageViewer.current.splide.length-1)
    {
      imageViewer.current.go(index-1);
    }
    else {
      imageViewer.current.go(index+1);
    }
    imageViewer.current.go(index);
  }

  const imageViewerGo = async (index, target) => {
    
    if(index===0) {
      imageViewer.current.go(index+1);
      await new Promise(r => setTimeout(r, 10));
      imageViewer.current.go(index);
    } else {
      imageViewer.current.go(index);
    }
    //console.log("Going to image: "+index);
/*     const elements = document.querySelectorAll('.window');
    var isDisplayed = await elements.forEach( async (el) => {
      if(el.getAttribute("name")==="billeder") {
        if(el.style.display!="none") {
          return true
        }
      }
      return false;
    })
    if(isDisplayed) { */
      await new Promise(r => setTimeout(r, 250));
      target.style.cursor=''
      setLoadingImageViewer(false);
   /*  } */

    
  }

  const openImageViewerFirst = () => {
    const element = document.getElementsByName("billedfremviser");
    if(element) {
      element[0].style.display = "block";
    }
  }

  const toggleStartMenu = () => {
    const element = document.querySelector('.startMenu');
    let isOpen = element.style.display === 'block';
    if(isOpen) {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }

  const closeStartMenu = () => {
    const element = document.querySelector('.startMenu');
    element.style.display = 'none';
  }

  

  const resizeGameWindow = () => {
    let element = document.querySelector('#windowButtonsTargetGame');
    console.log(element);
    if(element) {
      let p1 = element;
      let p2 = p1.parentElement;
      let p3 = p2.parentElement;
      let content = p3.children[1];
      let isMaximized = p3.style.width === '100%';
      let number = parseInt(p3.getAttribute('dataid'));
      let arr = heightWidthArray;
      if(isMaximized===false) {
        arr[number].x = p3.style.left;
        arr[number].y = p3.style.top;
        setHeightWidthArray(arr);
        arr[number].height = p3.offsetHeight-2;
        arr[number].width = p3.offsetWidth-2;
        p3.style.paddingTop= '0px';
        p3.style.width = '100%';
        p3.style.height = '100%';
        p3.style.position = 'absolute';
        p3.style.top = "0%";
        p3.style.left = "0%";
        p3.style.resize = 'none';
        p3.style.border = 'none';
        p2.style.cursor = 'default';
        p2.style.marginTop = '0px';
        content.style.paddingTop = '31px';
        p2.children[0].style.pointerEvents = 'none';
      } else {
        p3.style.paddingTop= '30px';
        p3.style.width = heightWidthArray[number].width+'px';
        p3.style.height = heightWidthArray[number].height+'px';
        p3.style.position = 'absolute';
        p3.style.resize = 'both';
        p3.style.top = heightWidthArray[number].y;
        p3.style.left = heightWidthArray[number].x;
        p3.style.border = '1px solid black';
        p2.style.cursor = 'default';
        p2.style.marginTop = '-31px';
        content.style.paddingTop = '0px';
        p2.children[0].style.pointerEvents = 'initial';
      }
    }
  }

  const resizeWindow = (e) => {
    if(e) {
      let p1 = e.target.parentElement;
      let p2 = p1.parentElement;
      let p3 = p2.parentElement;
      let content = p3.children[1];
      let isMaximized = p3.style.width === '100%';
      let number = parseInt(p3.getAttribute('dataid'));
      let arr = heightWidthArray;
      if(isMaximized===false) {
        arr[number].x = p3.style.left;
        arr[number].y = p3.style.top;
        setHeightWidthArray(arr);
        arr[number].height = p3.offsetHeight-2;
        arr[number].width = p3.offsetWidth-2;
        p3.style.paddingTop= '0px';
        p3.style.width = '100%';
        p3.style.height = '100%';
        p3.style.position = 'absolute';
        p3.style.top = "0%";
        p3.style.left = "0%";
        p3.style.resize = 'none';
        p3.style.border = 'none';
        p2.style.cursor = 'default';
        p2.style.marginTop = '0px';
        content.style.paddingTop = '31px';
        p2.children[0].style.pointerEvents = 'none';
      } else {
        p3.style.paddingTop= '30px';
        p3.style.width = heightWidthArray[number].width+'px';
        p3.style.height = heightWidthArray[number].height+'px';
        p3.style.position = 'absolute';
        p3.style.resize = 'both';
        p3.style.top = heightWidthArray[number].y;
        p3.style.left = heightWidthArray[number].x;
        p3.style.border = '1px solid black';
        p2.style.cursor = 'default';
        p2.style.marginTop = '-31px';
        content.style.paddingTop = '0px';
        p2.children[0].style.pointerEvents = 'initial';
      }
    }

  }

  const resizeImageViewerNotFromClick = () => {
    const element = document.querySelector("#imageViewerResizer");
    if (element) {
      element.click();
    }
  }

  const resizeImageViewer = async (e) => {
    if(e) {
      setLoadingImageViewer(true);
      let p1 = e.target.parentElement;
      let p2 = p1.parentElement;
      let p3 = p2.parentElement;
      let content = p3.children[1];
      let isMaximized = p3.style.width === '100%';
      let number = parseInt(p3.getAttribute('dataid'));
      let arr = heightWidthArray;
      if(isMaximized===false) {
        let elements = document.querySelectorAll('.photoViewerImageWrapper');
        for(let i = 0; i<elements.length; i++) {
          elements[i].classList.replace('photoViewerImageWrapper', 'photoViewerImageWrapperFullscreen');
        }
        arr[number].x = p3.style.left;
        arr[number].y = p3.style.top;
        setHeightWidthArray(arr);
        arr[number].height = p3.offsetHeight-2;
        arr[number].width = p3.offsetWidth-2;
        p3.style.width = '100%';
        p3.style.height = '100%';
        p3.style.position = 'absolute';
        p3.style.top = "0%";
        p3.style.left = "0%";
        p3.style.resize = 'none';
        p3.style.border = 'none';
        p3.style.paddingTop = '0px'
        p2.style.cursor = 'default';
        p2.style.marginTop = '0px';
        content.style.paddingTop = '31px';
        p2.children[0].style.pointerEvents = 'none';
      } else {
        let elements = document.querySelectorAll('.photoViewerImageWrapperFullscreen');
        for(let i = 0; i<elements.length; i++) {
          elements[i].classList.replace('photoViewerImageWrapperFullscreen', 'photoViewerImageWrapper');
        }
        p3.style.width = heightWidthArray[number].width+'px';
        p3.style.height = heightWidthArray[number].height+'px';
        p3.style.position = 'absolute';
        p3.style.resize = 'both';
        p3.style.border = '1px solid black';
        p3.style.top = heightWidthArray[number].y;
        p3.style.left = heightWidthArray[number].x;
        p2.style.cursor = 'default';
        p2.style.marginTop = '-31px';
        p3.style.paddingTop = '30px'
        content.style.paddingTop = '0px';
        p2.children[0].style.pointerEvents = 'initial';
      }
       // Sleep for 3 seconds
    await new Promise(r => setTimeout(r, 10));
      updateImageViewer();
      await new Promise(r => setTimeout(r, 250));
      setLoadingImageViewer(false);
    }
    
  }

  const handleClickOutside = (e) => {

    let display = document.querySelector('.startMenu').style.display;
    if(display ==='none') {
      return;
    }

  if(bottomBar.current.contains(e.target) || startMenu.current.contains(e.target)) {
    // the click happened in the component
    // code to handle the submit button
    // submit();
    return;
} 
      document.querySelector('.startMenu').style.display = 'none';
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}

useEffect(()=>{
  fetchImages();
  fetchAudio();

  document.addEventListener("mousedown", handleClickOutside, false);
  let windows = document.querySelectorAll(".window");
  let icons = document.querySelectorAll(".desktopIcon");
  let tempHeightWidthArray = [];
  let tempZIndexArray = [];
  for(let i = 0; i<icons.length; i++) {
    icons[i].addEventListener('touchmove', (e) =>{ e.preventDefault(); }, { passive:false });
    dragElement(icons[i],true);
  }
  for(let i = 0; i<windows.length; i++) {
    windows[i].children[0].children[0].addEventListener('touchmove', (e) =>{ e.preventDefault(); }, { passive:false });
    dragElement(windows[i],false);
    let w = 320;
    let h = 240;
    let tempObj = {height: h, width: w, first: true, x: '10%', y: '10%'}
    let tempObjZIndex = {dataId: windows[i].getAttribute("dataid")}
    tempHeightWidthArray.push(tempObj);
    tempZIndexArray.push(tempObjZIndex);

  }
  setZIndexArray(tempZIndexArray);
  setHeightWidthArray(tempHeightWidthArray);
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
  }
},[])








function dragElement(elmnt, increments) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (elmnt.children[0].children[0]) {
    /* if present, the header is where you move the DIV from:*/
    if(increments)
    {
    elmnt.children[0].children[0].onmousedown = dragMouseDownIcons;
    elmnt.children[0].children[0].ontouchstart = dragTouchDownIcons;
    }
    else {
    elmnt.children[0].children[0].onmousedown = dragMouseDown;
    elmnt.children[0].children[0].ontouchstart = dragTouchDown;
    }
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    if(increments)
    {
      elmnt.onmousedown = dragMouseDownIcons;
      elmnt.ontouchstart = dragTouchDownIcons;
    } else {
      elmnt.onmousedown = dragMouseDown;
      elmnt.ontouchstart = dragTouchDown;
    }
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function dragTouchDownIcons(e) {
    e = e || window.event;
    // get the mouse cursor position at startup:
    pos3 = e.touches[0].clientX;
    pos4 = e.touches[0].clientY;
    document.ontouchend = closeDragElementTouch;
    // call a function whenever the cursor moves:
    document.ontouchmove = elementDragTouchIncrements;
  }

  function dragTouchDown(e) {
    e = e || window.event;
    // get the mouse cursor position at startup:
    pos3 = e.touches[0].clientX;
    pos4 = e.touches[0].clientY;
    document.ontouchend = closeDragElementTouch;
    // call a function whenever the cursor moves:
    document.ontouchmove = elementDragTouch;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function elementDragTouch(e) {
    e = e || window.event;
    // calculate the new cursor position:
    pos1 = pos3 - e.touches[0].clientX;
    pos2 = pos4 - e.touches[0].clientY;
    pos3 = e.touches[0].clientX;
    pos4 = e.touches[0].clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }


  function dragMouseDownIcons(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDragIncrements;
  }
  function elementDragIncrements(e) {
    e = e || window.event;
    e.preventDefault();
    let increments = 8;
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    if(pos1>increments || pos1 <-increments)
    {
      setIsDraggingDesktopIcon(true);
      pos3 = e.clientX;
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
    if(pos2 >increments || pos2 < -increments) {
      setIsDraggingDesktopIcon(true);
      pos4 = e.clientY;
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    }
  }
  function elementDragTouchIncrements(e) {
    e = e || window.event;
    let increments = 8;
    // calculate the new cursor position:
    pos1 = pos3 - e.touches[0].clientX;
    pos2 = pos4 - e.touches[0].clientY;
    if(pos1>increments || pos1 <-increments)
    {
      setIsDraggingDesktopIcon(true);
      pos3 = e.touches[0].clientX;
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }
    if(pos2 >increments || pos2 < -increments) {
      setIsDraggingDesktopIcon(true);
      pos4 = e.touches[0].clientY;
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    }
  }

      
      /* elmnt.style.left = (elmnt.offsetLeft - pos1) + "px"; */
      
      
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    setDraggingToFalse();
    document.onmouseup = null;
    document.onmousemove = null;
  }

  function closeDragElementTouch() {
    /* stop moving when mouse button is released:*/
    setDraggingToFalse();
    document.ontouchend = null;
    document.ontouchmove = null;
  }

  async function setDraggingToFalse() {
    await new Promise(r => setTimeout(r, 100));
    setIsDraggingDesktopIcon(false);
  }




  return (
<div className='desktopWrapper'>
<div className='desktopIcon' style={{left: '50px', top: '50px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('papirkurv')}}><img className='iconImage' src={require('./images/bin.png')}/><p className='iconLabel'>Papirkurv</p></div>
</div>
<div className='desktopIcon' style={{left: '150px', top: '50px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('live')}}><img className='iconImage' src={require('./images/robot.png')}/><p className='iconLabel'>Koncerter</p></div>
</div>
<div className='desktopIcon' style={{left: '250px', top: '50px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('merch');/* handleDoubleClick('merch') */}}><img className='iconImage' src={require('./images/merch.png')}/><p className='iconLabel'>Merch & Vinyler</p></div>
</div>
<div className='desktopIcon' style={{left: '50px', top: '200px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('billeder')}}><img className='iconImage' src={require('./images/billeder.png')}/><p className='iconLabel'>Billeder</p></div>
</div>
<div className='desktopIcon' style={{left: '150px', top: '200px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('kontakt')}}><img className='iconImage' src={require('./images/emailicon.png')}/><p className='iconLabel'>Kontakt</p></div>
</div>
<div className='desktopIcon' style={{left: '250px', top: '200px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('nyhedsbrev')}}><img className='iconImage' src={require('./images/postboxIcon.png')}/><p className='iconLabel'>Nyhedsbrev</p></div>
</div>
<div className='desktopIcon' style={{left: '50px', top: '350px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('elendigsoftwareplade')}}><img className='iconImage' src={require('./images/vinylArtwork.png')}/><p className='iconLabel'>Elendig Software</p></div>
</div>
{/*<div className='desktopIcon' style={{left: '150px', top: '350px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('købPlade')}}><img className='iconImage' src={require('./images/dollarIcon.png')}/><p className='iconLabel'>Køb Elendig Software LP!</p></div>
</div>*/}
{isMobile===false && 
  <div className='desktopIcon' style={{left: '150px', top: '350px' }}>
<div className='iconWrapper' onClick={()=>{handleClick('zpspil')}}><img className='iconImage' src={require('./images/ElendigSoftwareSpil.png')}/><p className='iconLabel'>ElendigSoftwareSpillet.exe</p></div>
</div>
}



{/*   <fos-icon href="live" style={{left: '50px', top: '50px' }}><div className='iconWrapper' ><img className='iconImage' src={require('./images/ZPHvid.png')}/><p className='iconLabel'>Koncerter</p></div></fos-icon>
  <fos-icon href="merch" style={{left: '150px', top: '50px'}}><div onDoubleClick={()=>{openWindow('merch')}}><img className='iconImage' src={require('./images/ZPHvid.png')}/><p className='iconLabel'>Merch</p></div></fos-icon>
  <fos-icon href="billeder" style={{left: '250px', top: '50px'}}><div onDoubleClick={()=>{openWindow('billeder')}}><img className='iconImage' src={require('./images/ZPHvid.png')}/><p className='iconLabel'>Billeder</p></div></fos-icon>
  <fos-icon href="ravage" style={{left: '50px', top: '150px'}}><div onDoubleClick={()=>{openWindow('ravage')}}><img className='iconImage' src={require('./images/ravage.png')}/><p className='iconLabel'>Ravage</p></div></fos-icon> */}
{/* <fos-window name="live" title="Koncerter">
  <h2>AÆÆXXXSLIXAK</h2>
</fos-window> */}
<div dataid='0'  className='window' name='live' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}}  tabIndex='0' onClick={(e)=>{updateZIndexArray(e.target); }}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{updateZIndexArray(e.target); }}><p style={{pointerEvents: 'none'}}>Koncerter</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('live')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1>Zar Paulo Elendig Software Tour 2023</h1>
  <p><a href="https://tix.to/zarpaulo" target='_blank'>FIND LINK TIL BILLETTER HER!</a></p>
  <img alt='ZP Live Efterår 2023' className='windowImage' src={require("../src/images/tour2023.jpg")} />
  </div>
{/*   <div className='windowResizer'></div> */}
</div>
<div dataid='1' className='window' name='merch' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}}  tabIndex='1' onClick={(e)=>{updateZIndexArray(e.target); }}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Merch</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('merch')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1>Merch</h1>
  <p><a href="https://www.beatdown.dk/kunstnere/zar-paulo" target='_blank'>DEN NYESTE KOLLEKTION HER!</a></p>
  <div className='photoGalleryWindow' style={{gridTemplateColumns: 'repeat(auto-fill, 200px)'}}>
    <div className='merchItem' onClick={()=>{window.open("https://www.beatdown.dk/kunstnere/zar-paulo/p/zp003-zar-paulo-elendig-software-langaermet-t-shirt/black","_blank"); return false;}}>
    <img className='merchItemImage' src={require("../src/images/merch_long.png")}/>
    <p>Long Sleeve - 300DKK</p>
    </div>
    <div className='merchItem' onClick={()=>{window.open("https://www.beatdown.dk/kunstnere/zar-paulo/p/zp001-zar-paulo-elendig-software-t-shirt/black","_blank"); return false;}}>
    <img className='merchItemImage' src={require("../src/images/merch_tee_black.png")}/>
    <p>T-shirt Sort - 220DKK</p>
    </div>
    <div className='merchItem' onClick={()=>{ window.open("https://www.beatdown.dk/kunstnere/zar-paulo/p/zp002-zar-paulo-elendig-software-t-shirt/blue","_blank"); return false;}}>
    <img className='merchItemImage' src={require("../src/images/merch_tee_blue.png")}/>
    <p>T-shirt Blå - 220DKK</p>
    </div>
    </div>
  </div>
{/*   <div className='windowResizer'></div> */}
</div> 

<div dataid='2' className='window' name='billeder' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='2' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Billeder</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('billeder')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
{ loadImages===true ? <div style={{height: 'inherit', display: 'grid', placeItems: 'center'}}>
<img className='loadRing' src={require('./images/loadicon.png')} alt='load'/>
  {/* <h1 style={{height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'-18px'}}>
    Indlæser...</h1> */}
</div> : 
  <div className='photoGalleryWindow' /* style={{maxWidth: imagesData.length*100+'px'}} */>
    {imagesData.map((photo, i) => (
  <div className='photoItem' onClick={(e)=>{handleClick('billedfremviser',i, e)}}>
  <div className='photoItemWrapper'><img className='photoImage' src={photo.src}/></div><p className='photoLabel'>{photo.name}</p>
</div>
    ))} 

  </div>
  }
  </div>
{/*   <div className='windowResizer'></div> */}
</div>
<div dataid='5' className='window' name='ravage' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Ravage</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('ravage')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/ravage.png')}/>Ravage<img style={{width:'20px'}} src={require('./images/ravage.png')}/></h1>
  <p>
      Så du lader lige så stille kroppen bryde ned med vilje<br></br>
      De frygter det er din sidste udvikling det gør jeg ikke<br></br>
      Som en skov der brænder lyst i vinden mod jord der har glædet sig til det<br></br>
      Hvorfor skulle du lade dem redde noget du helst vil efterlade<br></br>
    <br></br>
      Du trækker i de løse ender<br></br>
      Under skindet<br></br>
      Lige så stille<br></br>
      Man vil binde dine kolde hænder<br></br>
      Men at forsvinde<br></br>
      Ligger til familien<br></br>
    <br></br>
      Bare lov mig du kommer tilbage<br></br>
      I et andet stadie<br></br>
      Jeg ved godt hvad jeg sagde<br></br>
      Om at skabe<br></br>
      En masse ravage<br></br>
    <br></br>
      Bare ik gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik’ nu<br></br>
      
      Bare ik’ gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik nu<br></br>
    <br></br>
      De frygter at du bliver som en sky af støvpartikler<br></br>
      Småt nok til vi ikke kan se dig let nok til du aldrig bliver ét sted<br></br>
      Men så længe du ser beskeder<br></br>
      Så længe jeg ved du lever<br></br>
    <br></br>
      Kan du trække i de løse ender<br></br>
      Under skindet<br></br>
      Lige så stille<br></br>
      Man vil binde dine kolde hænder<br></br>
      Men at forsvinde<br></br>
      Ligger til familien<br></br>
    <br></br>
      Bare lov mig du kommer tilbage<br></br>
      I et andet stadie<br></br>
      Jeg ved godt hvad jeg sagde<br></br>
      Om at skabe<br></br>
      En masse ravage<br></br>
    <br></br>
      Bare ik gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik nu<br></br>
    <br></br>
      Bare ik gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik nu<br></br>
    <br></br>
      Bare lov mig du kommer tilbage<br></br>
      I et andet stadie<br></br>
      Jeg ved godt hvad jeg sagde<br></br>
      Om at skabe<br></br>
      En masse ravage<br></br>
    <br></br>
      Bare ik gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik nu<br></br>
    <br></br>
      Bare ik gå<br></br>
      Ik gå ud<br></br>
      I hvert fald ik helt<br></br>
      I hvert fald ik nu<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='6' className='window' name='elendig' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Elendig Software</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('elendig')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/elendig.png')}/>Elendig Software<img style={{width:'20px'}} src={require('./images/elendig.png')}/></h1>
  <p>
      Lige meget hvad han giver sig til<br></br>
      Om han sidder eller går rundt et sted<br></br>
      Der skal mere end en galakse til<br></br>
      At holde hans opmærksomhed<br></br>
      <br></br>
      Det er ikke fordi han forstår hvad han ser<br></br>
      Han gider bare ikke se på det mere<br></br>
      Der skal mere end en galakse til<br></br>
      At lade ham føle der er steder han bør være<br></br>
      <br></br>
      Der er ikke det mennesker ikke kan lære<br></br>
      Hvis til december han stadig ikke er i stand<br></br>
      Så må han være<br></br>
      <br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så må han være<br></br>
      Lange ben og arme og<br></br> 
      Elendig software<br></br>
      Så må han være<br></br>
      <br></br>
      Forbi pointen som et dårligt kast<br></br>
      Væk i mængden som et stykke plast<br></br>
      Der skal mere end en galakse til<br></br>
      At lade ham føle det er værd at holde fast<br></br>
      <br></br>
      Der er ikke det mennesker ikke kan opnå<br></br>
      Hvis til december han stadig ikke er en mand<br></br>
      Så giver jeg op på<br></br>
      <br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så må han være<br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      <br></br>
      Har han måske noget bedre at give sig til<br></br>
      Prøv ikke at le om lidt når han vælter og siger<br></br>
      Det er os der ikke kan se<br></br>
      <br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så må han være<br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så må han være<br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så giver jeg op på<br></br>
      Lange ben og arme og<br></br>
      Elendig software<br></br>
      Så må han være<br></br>
      Lange ben og arme og<br></br> 
      Elendig software<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='7' className='window' name='etpar' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Et Par Øjne</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('etpar')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/etPar.png')}/>Et Par Øjne<img style={{width:'20px'}} src={require('./images/etPar.png')}/></h1>
  <p>
      Det meste jeg siger<br></br>
      Lyder som det skal<br></br>
      Det meste jeg ligner<br></br>
      Ligner noget halvt<br></br>
    <br></br>
      Og jeg ved ikke hvad jeg tænker mere<br></br>
      Mine lunger<br></br>
      Føles som et dyr der bare er<br></br>
      Gæst i mig<br></br>
    <br></br>
      Mine øjne<br></br>
      Er et par øjne<br></br>
      Tænker på at aflevere dem<br></br>
      Før jeg går ud og bliver væk igen<br></br>
    <br></br>
      Det meste jeg ønsker<br></br>
      Har jeg faktisk aldrig set<br></br>
      De fleste jeg elsker<br></br>
      Dem mister jeg om lidt<br></br>
    <br></br>
      Og jeg ved ikke hvem der taler mere<br></br>
      Mine lunger<br></br>
      Føles som et dyr der bare er<br></br>
      Gæst i mig<br></br>
    <br></br>
      Mine øjne<br></br>
      Er et par øjne<br></br>
      Tænker på at aflevere dem<br></br>
      Før jeg går ud og bliver væk igen<br></br>
    <br></br>
      Mine lemmer<br></br>
      er instrumenter<br></br>
      Tænker på at afmontere dem<br></br>
      Før jeg går ud og bliver væk igen<br></br>
    <br></br>
      Mine øjne<br></br>
      Er et par øjne<br></br>
      Tænker på at aflevere dem<br></br>
      Før jeg går ud og bliver<br></br>
    <br></br>
      Før jeg går ud og bliver<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='8' className='window' name='ikgå' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Ik Gå Så Langt</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('ikgå')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/ikGå.png')}/>Ik Gå Så Langt<img style={{width:'20px'}} src={require('./images/ikGå.png')}/></h1>
  <p>
  Hvis hvad du vil er<br></br>
  Den anden vej<br></br>
  Bliver det ikke lige til<br></br>
  Det lover jeg<br></br>
  Tag en dag off og rapporter til mig<br></br>
    <br></br>
    For du har et godt hoved<br></br>
    Men sårbare hænder<br></br>
    Gaden er et rod<br></br>
    Jeg har magtfulde venner<br></br>
    Og afstand avler vold<br></br>
    Godt vi er et hold<br></br>
    Du og jeg<br></br>
    <br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se<br></br>
    <br></br>
    Og begynder dine tanker at klare op<br></br>
    Er du så langt jeg snart ik kan se dig<br></br>
    Træk vejret<br></br>
    Tænk dig om<br></br>
    Er det dét værd hvis du ik kan se mig<br></br>
    To som os vi magter ik den leg<br></br>

    <br></br>
    For du har et godt hoved<br></br>
    Men sårbare hænder<br></br>
    Gaden er et rod<br></br>
    Jeg har magtfulde venner<br></br>
    Og afstand avler vold<br></br>
    Godt vi er et hold<br></br>
    Du og jeg<br></br>
    <br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se<br></br>
    <br></br>
    Pas nu på dig selv ven<br></br>
    Pas nu på dig selv<br></br>
    Pas nu på dig selv ven<br></br>
    Pas nu på dig selv<br></br>

    <br></br>
    Jeg ved at du ik har en plan<br></br>
    Bare tag den så langt du nu kan<br></br>
    Ses når du er træt<br></br>
    Når du har grædt<br></br>
    Kammerat<br></br>
    Bare tag den<br></br>
    Så langt du nu kan<br></br>
    Pas nu på dig selv<br></br>
    Pas nu på dig<br></br>

    <br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se dig<br></br>
    Ik gå så langt så jeg ik kan se<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='9' className='window' name='intra' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Interessantmand</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('intra')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/intra.png')}/>Interessantmand<img style={{width:'20px'}} src={require('./images/intra.png')}/></h1>
  <p>
  Jeg har booket alle taxa’er i byen<br></br>
Så du ikke kan komme hjem<br></br>
Jeg har købt alle sengene i byen<br></br>
Så du ikke kan gå i seng<br></br>
    <br></br>
    Jeg har taget alle dine piller<br></br>
Så du kan falde til ro<br></br>
Jeg har kaldt på Gud<br></br>
Så du slipper for at tro<br></br>
    <br></br>
    Jeg er Interessantmand<br></br>
En jeg-kan-alt-mand<br></br>
Sådan en der synes alt er fedt<br></br>
Selv kryptonit<br></br>
    <br></br>
    Jeg har navngivet dine børn<br></br>
Så du ikke skal tænke på det<br></br>
Jeg noterer alt du siger og gør<br></br>
Så du aldrig glemmer det<br></br>
    <br></br>
    Jeg har lavet en støbning<br></br>
Af dit ansigt i guld<br></br>
Hvis du ikke vil have det<br></br>
Så smider jeg den ud<br></br>
    <br></br>
    For jeg er Interessantmand<br></br>
En jeg-kan-alt-mand<br></br>
Sådan en der synes alt er fedt<br></br>
Selv kryptonit<br></br>
    <br></br>
    Kom nu<br></br>
Uh-uh-uh-hu<br></br>
Tager dig op gennem atmosfæren<br></br>
Jaa-ha-a-aa<br></br>
Kom nu<br></br>
Uh-uh-uh-hu<br></br>
Håber du kan holde vejret<br></br>
Jaa-haa<br></br>
    <br></br>
    Jeg er Interessantmand<br></br>
En jeg-kan-alt mand<br></br>
Har en hemmelig identitet<br></br>
Fra en fjern planet<br></br>
    <br></br>
    Jaaa-aa<br></br>
Jaaa-aa-aa<br></br>
Jeg kan se dig overalt i byen<br></br>
Med mit røntgensyn<br></br>
<br></br>
Jaaa-aa<br></br>
Jaaa-aa-aa<br></br>
Sådan en der altid ved besked<br></br>
Har usårlighed<br></br>
<br></br>
Jeg er Interessantmand<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='10' className='window' name='kanduholde' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Kan Du Holde Til At Holde Det Inde</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('kanduholde')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/kanDuHolde.png')}/>Kan Du Holde Til At Holde Det Inde<img style={{width:'20px'}} src={require('./images/kanDuHolde.png')}/></h1>
  <p>
  Går en omvej for at se om du er her<br></br>
Gennem skoven for jeg ved du kan lide træer<br></br>
Du var med i min film igen i går<br></br>
Kigger efter folk med samme farve hår<br></br>
    <br></br>
    Du ved hvad jeg er<br></br>
Jeg vil så gerne være meget mere<br></br>
Jeg ved hvad jeg er<br></br>
Jeg vil så gerne være<br></br>
Jeg vil så gerne være<br></br>
    <br></br>
    Kan du holde til at holde det inde i dig<br></br>
Kan du holde til at holde det inde<br></br>
Kan du holde til at støde ind i mig<br></br>
Kan du holde til at holde det inde<br></br>
    <br></br>
    Ser du er online så jeg logger af<br></br>
Skriver beskeder for at sende en anden dag<br></br>
Så en film for du sagde at den var god<br></br>
Hvad var det du sagde til mig om tro<br></br>
    <br></br>
    Kan du holde til at holde det inde i dig<br></br>
Kan du holde til at holde det inde<br></br>
Kan du holde til at støde ind i mig<br></br>
Kan du holde til at holde det inde<br></br>
    <br></br>
    Jeg synes du var svær at finde<br></br>
Så jeg er ikke færdig med dig vel<br></br>
    <br></br>
    Kan du holde til at holde det inde<br></br>
Kan du holde til at holde det inde i dig<br></br>
Kan du holde til at holde det inde<br></br>
Kan du holde til at støde ind i mig<br></br>
    <br></br>
    Kan du holde til at holde det inde<br></br>
Kan du holde til at holde det inde i dig<br></br>
Kan du holde til at holde det inde<br></br>
Kan du holde til at støde ind i mig<br></br>
Kan du holde til at holde det inde<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='11' className='window' name='klap' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Klap For Fædrelandet</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('klap')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/klap.png')}/>Klap For Fædrelandet<img style={{width:'20px'}} src={require('./images/klap.png')}/></h1>
  <p>
  Far har købt en bil og cruiser ud på landevejen og vælter<br></br>
Mor er blevet hjemme hun ved ikke hvordan WiFi’en tænder<br></br>
    <br></br>
    Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
    <br></br>
    Bertel drømmer om at lukke grænsen for at passe på kulturen<br></br>
Han spiser drømmekage og spiller poker med sig selv på computer<br></br>
    <br></br>
    Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
    <br></br>
    Og Københavns smukke gader de er fulde af støj<br></br>
Der siger hallo-lo-lo-lo kender vi dig<br></br>
Og når han synger, synger han om et vidunderligt døgn<br></br>
Vi siger hallo-lo-lo-lo-lo-lo-lo forsømte Charlie<br></br>
    <br></br>
    Spritbilisten Jan forårsager alt for tidlig død på Dusinvej<br></br>
Fie blev kun 18, man er enige om at Jan burde hænge sig<br></br>
Så det gjorde han<br></br>
    <br></br>
    Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
Klap for fædrelandet<br></br>
    <br></br>
    Og Københavns smukke gader de er fulde af støj<br></br>
Der siger hallo-lo-lo-lo kender vi dig<br></br>
Og når han synger, synger han om et vidunderligt døgn<br></br>
Vi siger hallo-lo-lo-lo-lo-lo-lo forsømte Charlie<br></br>
<br></br>
Klap for fædrelandet<br></br>
Forsømte Charlie<br></br>
Klap for fædrelandet<br></br>
<br></br>
Og Københavns smukke gader de er fulde af støj<br></br>
Der siger hallo-lo-lo-lo kender vi dig<br></br>
Og når han synger, synger han om et vidunderligt døgn<br></br>
Vi siger hallo-lo-lo-lo-lo-lo-lo forsømte Charlie<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='12' className='window' name='paradis' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Tæt På Paradis</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('paradis')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/paradis.png')}/>Tæt På Paradis<img style={{width:'20px'}} src={require('./images/paradis.png')}/></h1>
  <p>
  Mærker mine hænder male datalogi og får åndenød<br></br>
Talte om en tungere kalibrering af din tankevirksomhed<br></br>
I morgen er det vinter<br></br>
Drømmer om at finde dig <br></br>
Ubemandet, fri som du var<br></br>
Kigger ned ad gaden med alt hvad jeg har<br></br>
Men hvor er dit forsvar ven<br></br>
    <br></br>
    Vil du identificere min story, MySpace og MobilePay<br></br>
Du ser kun emojis, offline, jeg står af<br></br>
Og hvis du bliver konfronteret med dit ansigt<br></br>
Så send det afsted til mig igen<br></br>
Så er vi tæt på paradis<br></br>
    <br></br>
    Tror du falder væk hver gang du falder ud kan ik se dig mer<br></br>
Tror et øjeblik på at vi to er kommet så langt som vi kan vær<br></br>
Tænker på at sende et svar<br></br>
Og fortælle dig hvad det var<br></br>
Tænker på at sende et svar<br></br>
Om hvad det var<br></br>
    <br></br>
    Vil du identificere min story, MySpace og MobilePay<br></br>
Du ser kun emojis, offline, jeg står af<br></br>
Og hvis du bliver konfronteret med dit ansigt<br></br>
Så send det afsted til mig igen<br></br>
Så er vi tæt på paradis<br></br>
    <br></br>
    AI SOLO<br></br>
    <br></br>
    Vil du identificere min story, MySpace og MobilePay<br></br>
Du ser kun emojis, offline, jeg står af<br></br>
Og hvis du bliver konfronteret med dit ansigt<br></br>
Så send det afsted til mig igen<br></br>
Så er vi tæt på paradis<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='13' className='window' name='stop' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Stop Believing</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('stop')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/stop.png')}/>Stop Believing<img style={{width:'20px'}} src={require('./images/stop.png')}/></h1>
  <p>
  En P-plads blank af regn<br></br>
Og alting rører dig<br></br>
Præcis som det står skrevet<br></br>
Og som det bliver fortalt<br></br>
Går du i et med havet<br></br>
Det gennemlyser alt<br></br>
    <br></br>
    Tænder radioen<br></br>
På en kystvej<br></br>
20’erne er tilbage for<br></br>
At køre den med dig<br></br>
    <br></br>
    Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
    <br></br>
    Og venner ringer tit<br></br>
De siger du er gået for vidt<br></br>
Og du har så meget at sige<br></br>
Men ingen vil høre mere<br></br>
Om hvem du er indeni<br></br>
Når det ikke er det de ser<br></br>
    <br></br>
    Tænder radioen<br></br>
På en kystvej<br></br>
20’erne er tilbage for<br></br>
At eje den med dig<br></br>
    <br></br>
    Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
<br></br>
You know you’ve got to<br></br>
<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop Believing<br></br>
Stop<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='14' className='window' name='tiger' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Tiger</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('tiger')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/tiger.png')}/>Tiger<img style={{width:'20px'}} src={require('./images/tiger.png')}/></h1>
  <p>
  Blev en smule lettet<br></br>
Da du gik på toilettet<br></br>
Sommerfugle i min mavesæk<br></br>
Du kender ikke til det<br></br>
Hvad skal jeg dog gøre ved det<br></br>
Hvorfor blev jeg egentlig ikke væk<br></br>
    <br></br>
    Er du fra Jylland<br></br>
Det er jeg også<br></br>
Så er der ikke nogen grund til<br></br>
At blive ved med at slås<br></br>
    <br></br>
    Alle dem der bløder er velkommen<br></br>
Til endnu en ny udgave af jagtsæsonen<br></br>
Jeg tager det slips på jeg kan nå<br></br>
Trimmer hagen ovenpå<br></br>
Booker alle billetter<br></br>
Så ingen andre får dem åh-u-åh-åh-åh<br></br>
    <br></br>
    Find dig et instinkt<br></br>
Nu må du ikke være flink<br></br>
Nu er det slut med at fjolle rundt<br></br>
Du har alt for mange venner <br></br>
Du skal have flere fjender <br></br>
Og problemer det er også sundt<br></br>
Og hvis du tænker før du taler<br></br>
Hvis du falder lidt i staver<br></br>
Så er du uegnet<br></br>
Lav en ny account<br></br>
Make it better make it count<br></br>
Der er andre veje<br></br>
    <br></br>
    Er du fra Jylland<br></br>
Det er jeg også<br></br>
Så er der ikke nogen grund til<br></br>
At blive ved med at slås<br></br>
<br></br>
Alle dem der bløder er velkommen<br></br>
Til endnu en ny udgave af jagtsæsonen<br></br>
Jeg tager det slips på jeg kan nå<br></br>
Trimmer hagen ovenpå<br></br>
Booker alle billetter<br></br>
Så ingen andre får dem åh-u-åh-åh-åh<br></br>
<br></br>
Er du fra Jylland<br></br>
Det er jeg også<br></br>
Så er der endnu mere grund til <br></br>
At blive ved med at slås<br></br>
<br></br>
Alle dem der bløder er velkommen<br></br>
Til endnu en ny udgave af jagtsæsonen<br></br>
Så tag din hvidvin, Sertralin<br></br>
Sid over i næste time<br></br>
Klap dig selv på skulderen <br></br>
Alle synes jo at du gør det fint<br></br>
Tag en dag ad gangen ven<br></br>
Ses når du er god igen<br></br>
Kom og hyg i stuen sidst på ugen <br></br>
Når du bare gerne vil hjem<br></br>
<br></br>
1 2<br></br>
1 2 3 4<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='15' className='window' name='dejlig' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Jeg Synes Du Er Dejlig</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('dejlig')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1><img style={{width:'20px'}} src={require('./images/dejlig.png')}/>Jeg Synes Du Er Dejlig<img style={{width:'20px'}} src={require('./images/dejlig.png')}/></h1>
  <p>
  Der er gået<br></br>
Spekulation<br></br>
I mit hoved<br></br>
Jeg bager på nogen<br></br>
Jeg bager på<br></br>
    <br></br>
    Intet specielt<br></br>
Et lille minde jeg har gemt i mig selv<br></br>
Sidst jeg så dig med ham<br></br>
Havde I det godt<br></br>
Jeg kan huske jeg tænkte <br></br>
Åh jeg tænkte det nok<br></br>
    <br></br>
    Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
    <br></br>
    Der er ikke<br></br>
Mere at sige<br></br>
Du får aldrig<br></br>
Det at vide<br></br>
    <br></br>
    Intet specielt<br></br>
Et lille minde jeg har gemt i mig selv<br></br>
Sidst jeg så dig med ham<br></br>
Havde I det godt<br></br>
Jeg kan huske jeg tænkte<br></br>
Åh jeg tænkte det nok<br></br>
<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
Jeg synes du er dejlig<br></br>
<br></br>
Intet specielt<br></br>
Et lille minde jeg har gemt i mig selv<br></br>
Sidst jeg så dig med ham <br></br>
Havde I det godt<br></br>
Jeg kan huske jeg tænkte<br></br>
Jeg synes du er dejlig<br></br>
      <br></br>
      <br></br>
      <br></br>
    </p>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>

<div dataid='3' className='window' name='elendigsoftwareplade' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Elendig Software</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('elendigsoftwareplade')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit',paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1> VORES DEBUT ALBUM 'ELENDIG SOFTWARE' ER UDE NU!</h1>
  {/* <p><a href="https://www.beatdown.dk/kunstnere/zar-paulo/p/zp200-zar-paulo-elendig-software-lp" title="Køb vinyl her" target='_blank'>KØB VINYL HER</a></p> */}
  <p> <a href="https://zarpaulo.lnk.to/album" title="Stream Elendig Software" target='_blank'>STREAM ELENDIG SOFTWARE</a></p>
{/*   <p> <a href="https://open.spotify.com/album/63y6pPYBqIlVWzA0xj796o" title="Lyt på spotify" target='_blank'>LYT PÅ SPOTIFY</a></p>
  <p> <a href="https://music.apple.com/us/album/elendig-software/1676308258" title="Lyt på apple music" target='_blank'>LYT PÅ APPLE MUSIC</a></p>
  <p>  <a href="https://musik.yousee.dk/album/287276048" title="Lyt på yousee musik" target='_blank'>LYT PÅ YOUSEE</a></p>
  <p>      <a href="https://www.youtube.com/watch?v=6uG2JH4NHNg&list=OLAK5uy_lCTuefx4lLFuG0MSgByIbu7XFo9TgfoYY&ab_channel=ZarPaulo-Topic" title="Lyt på youtube" target='_blank'>LYT PÅ YOUTUBE</a></p>
  <p>      <a href="https://www.deezer.com/da/album/415451767" title="Lyt på deezer" target='_blank'>LYT PÅ DEEZER</a></p> */}
  <h1>LYRIK</h1>
  <div className='photoGalleryWindow' /* style={{maxWidth: imagesData.length*100+'px'}} */>
    
<div className='iconWrapperSong' onClick={()=>{handleClick('ravage')}}><img className='iconImage' src={require('./images/ravage.png')}/><p className='iconLabel'>Ravage</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('elendig')}}><img className='iconImage' src={require('./images/elendig.png')}/><p className='iconLabel'>Elendig Software</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('etpar')}}><img className='iconImage' src={require('./images/etPar.png')}/><p className='iconLabel'>Et Par Øjne</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('ikgå')}}><img className='iconImage' src={require('./images/ikGå.png')}/><p className='iconLabel'>Ik Gå Så Langt</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('intra')}}><img className='iconImage' src={require('./images/intra.png')}/><p className='iconLabel'>Interessantmand</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('kanduholde')}}><img className='iconImage' src={require('./images/kanDuHolde.png')}/><p className='iconLabel'>Kan Du Holde Til At Holde Det Inde</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('klap')}}><img className='iconImage' src={require('./images/klap.png')}/><p className='iconLabel'>Klap For Fædrelandet</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('paradis')}}><img className='iconImage' src={require('./images/paradis.png')}/><p className='iconLabel'>Tæt På Paradis</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('stop')}}><img className='iconImage' src={require('./images/stop.png')}/><p className='iconLabel'>Stop Believing</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('tiger')}}><img className='iconImage' src={require('./images/tiger.png')}/><p className='iconLabel'>Tiger</p></div>
<div className='iconWrapperSong' onClick={()=>{handleClick('dejlig')}}><img className='iconImage' src={require('./images/dejlig.png')}/><p className='iconLabel'>Jeg Synes Du Er Dejlig</p></div>
  </div>
  </div>
{/*   <div className='windowResizer'></div> */}
</div>


<div dataid='8' className='window' name='papirkurv' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Papirkurv</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('papirkurv')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <div className='binWindow' >
  <div className='iconWrapperSong' onClick={()=>{handleClick('musik')}}><img className='iconImage' src={require('./images/folder.png')}/><p className='iconLabel'>@Z'_d¤9&2s=</p></div>
  </div>
  </div>
</div>

<div dataid='16' className='window' name='musik' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>@Z'_d¤9&2s=</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('musik')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  { loadMusic===true ? <div style={{height: 'inherit', display: 'grid', placeItems: 'center'}}>
  <img className='loadRing' src={require('./images/loadicon.png')} alt='load'/>
{/*   <h1 style={{height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'-18px'}}>
    Indlæser...</h1> */}
</div> : 
  <div className='photoGalleryWindow' >
  {musicData.map((music, i) => (
  <div className='iconWrapperSong' onClick={(e)=>{handleClick('musikafspiller'); setAudioPlaying(music.name); handlePlayAudio(music.src);}}><img className='iconImage' src={require('./images/mp3icon.png')}/><p className='iconLabel'>{music.name}</p></div>
    ))} 
  </div>}
  </div>
</div>

<div dataid='17' className='window' name='musikafspiller' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>{"Musikafspiller - "+audioPlaying}</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={(e)=>{closeWindow('musikafspiller'); handleStopAudio();}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <AudioPlayer
      ref={audioPlayerRef}
      src={audioFile}
      showJumpControls={false}
      
      
      // other props here
    />
  </div>
</div>

<div dataid='19' className='window' name='kontakt' style={{top:'10%', left: '10%', width: '320px', height: '240px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Kontakt</p></div> <div className='windowButtons'><div className='windowButton' onClick={resizeWindow}>[]</div><div className='windowButton' onClick={()=>{closeWindow('kontakt')}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px'}}>
  <h1>KONTAKT</h1>
  <p>BOOKING: Niels Lindgreen // <a href='mailto:niels@theartist.eu' title='Kontakt Niels'>niels@theartist.eu</a></p>
  <p>ØVRIGE HENVENDELSER: <a href='mailto:aske@thebankmusic.com' title='Kontakt Aske'>aske@thebankmusic.com</a></p>
  </div>
</div>

  <div dataid='20' className='window' name='zpspil' style={{position: 'absolute', top:'0%', left: '0%', width: '100%', height: '100%', paddingTop: '31px', overflow: 'hidden', resize: 'none', border: 'none', zIndex: '1002!important'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>ElendigSoftwareSpillet.exe</p></div> <div className='windowButtons' id='windowButtonsTargetGame' style={{justifyContent: 'end', alignItems: 'center'}}><div className='windowButton' style={{marginRight: '4px'}} onClick={async ()=>{
    await unload();
    // Ready to navigate to another page.
    closeWindow('zpspil'); 
 setGameActivated(false)}}>x</div></div></div>
  <div className='windowContent' style={{height: 'inherit', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '0px', overflow: 'auto', minHeight: '240px', display: 'grid', alignItems: 'center', justifyContent: 'center'}}>
  {gameActivated===true &&
  <Fragment>
      {!isLoaded && (
        <p>Indlæser spil... {Math.round(loadingProgression * 100)}%</p>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{ visibility: isLoaded ? "visible" : "hidden", width: 1280, height: 720 }}
      />
    </Fragment>
    }
  </div>
</div>



<div dataid='4' className='window' id='photoViewerWindow' name='billedfremviser' style={{top:'10%', left: '10%', width: '520px', height: '640px', paddingTop: '30px', overflow: 'hidden'}} tabIndex='3' onClick={(e)=>{ updateZIndexArray(e.target);}}>
  <div className="windowHeader"><div className='headerDragArea' id='header' onMouseDown={(e)=>{ updateZIndexArray(e.target);}}><p style={{pointerEvents: 'none'}}>Billedfremviser</p></div> <div className='windowButtons' ><div className='windowButton' id='imageViewerResizer' onClick={resizeImageViewer}>[]</div><div className='windowButton' onClick={()=>{closeWindow('billedfremviser')}}>x</div></div></div>
 <div style={openImageViewer==true ? {width: 'inherit', height: '100%'} : {width: 'inherit', height: '0%'} }></div>
 <div className='loadingImageViewer' style={loadingImageViewer==true ? {width: 'inherit', height: '95%'} : {width: 'inherit', height: '0%'} }>
  <img className='loadRing' style={loadingImageViewer==true ? {visibility: 'visible'} : {visibility: 'hidden'}} src={require('./images/loadicon.png')} alt='load'/>
 </div>
  <div className='windowContent' style={{display: 'flex', alignItems: 'center', height: 'inherit', overflow: 'auto', minHeight: '240px', visibility: 'inherit'}} >
  {imagesData.length>0 ? 
  <Splide ref={imageViewer} aria-label="My Favorite Images" hasTrack={ false }

    options={ {
      keyboard: true,
         perPage: 1,
        focus: 'center',
        type: 'loop',
        width: '100%',
        speed: 250,
        pagination: false
      } }>
        <SplideTrack>
        {imagesData.map((photo, i) => ( 
        <SplideSlide  style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
          <div>
          
            <div className='photoViewerImageWrapper'> 
          <img className='photoViewerPhoto' src={photo.src} alt={i}/>
          
            </div> 
            <p style={{textAlign: 'center'}}>{photo.name}</p>
            </div>
        </SplideSlide>
         ))}  
      </SplideTrack>
        <div className="splide__arrows">
        <div className="splide__arrow splide__arrow--prev pageArrow imageViewerButton" ><p className='arrowInButton'>{'<'}</p></div>
        <div className="splide__arrow splide__arrow--next pageArrow imageViewerButton" ><p className='arrowInButton'>{'>'}</p></div>
      </div>  
    </Splide>
    :''}
  </div>

{/*   <div className='windowResizer'></div> */}
</div>
{/* <fos-window name="billeder" title="Billeder">
  <h1>ZPZPZPZP</h1>
  <p>Dette er et ZP vindue</p>
  <p><a href="#">Lyt til Elendig Software her</a></p>
</fos-window> */}

{/* <fos-window name="ravage" title="Ravage">
  <h1><img style={{width:'20px'}} src={require('./images/ravage.png')}/>Lyt til Ravage<img style={{width:'20px'}} src={require('./images/ravage.png')}/></h1>
  <p>En masse ravaaaageeee</p>
  <p><a href="#">Lyt HER!</a></p>
</fos-window> */}
<div className='bottomBar' ref={bottomBar}>
  <div className='bottomBarStartButton' onClick={toggleStartMenu}>
    <img alt='' className='bottomBarStartButtonLogo' src={require("../src/images/ZPSort.png")} />
  </div>
  <Clock className='clock' style={{color: 'black'}}/>
</div>
<div className='startMenu' ref={startMenu}>
  <div className='startMenuButtonWrapper'>
  <div className='startMenuButton' onClick={()=>{window.open("https://tix.to/zarpaulo","_blank"); return false; /*openWindow('live')*/}}>
    <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/robot.png')}/></div>
    <p>Koncerter</p>
  </div>
  <div className='startMenuButton' onClick={()=>{window.open("https://www.beatdown.dk/kunstnere/zar-paulo","_blank"); return false;}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px', marginTop: '-5px'}} src={require('./images/merch.png')}/></div>
    <p>Merch & Vinyler</p>
  </div>
  <div className='startMenuButton' onClick={()=>{openWindow('billeder')}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/billeder.png')}/></div>
    <p>Billeder</p>
  </div>
  <div className='startMenuButton' onClick={()=>{openWindow('elendigsoftwareplade')}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/vinylArtwork.png')}/></div>
    <p>Elendig Software</p>
  </div>
{/*   <div className='startMenuButton' onClick={()=>{window.open("https://www.beatdown.dk/kunstnere/zar-paulo/p/zp200-zar-paulo-elendig-software-lp","_blank"); return false;}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/dollarIcon.png')}/></div>
    <p>Køb Elendig Software LP!</p>
  </div> */}
  <div className='startMenuButton' onClick={()=>{openWindow('kontakt')}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/emailicon.png')}/></div>
    <p>Kontakt</p>
  </div>
  <div className='startMenuButton' id='bottomStartButton' onClick={()=>{window.open("https://thebankmusic.us14.list-manage.com/subscribe?u=f5b08f74f467a44feab8ab5b4&id=a0533bb8f4","_blank"); return false;}}>
  <div className='startMenuButtonIcon'><img className='startMenuIcon' alt='' style={{width:'40px'}} src={require('./images/postboxIcon.png')}/></div>
    <p>Nyhedsbrev</p>
  </div>
  </div>
{/*   <div className='startMenuButton' onClick={()=>{openWindow('ravage')}}>
  <div className='startMenuButtonIcon'><img alt='' style={{width:'40px'}} src={require('./images/ravage.png')}/></div>
    <p>Ravage</p>
  </div> */}
</div>
{/* <fos-bar>
  <fos-menu name={require("../src/images/ZPSort.png")}>
    <fos-menu-item href="live">
      Koncerter
    </fos-menu-item>
    <fos-menu-item href="merch">
      Merch
    </fos-menu-item>
    <fos-menu-item href="billeder">
      Billeder
    </fos-menu-item>
  </fos-menu>
  <Clock className='clock' style={{color: 'black'}}/>
</fos-bar> */}

</div>
  );
}

export default App;
