import { initializeApp } from '@firebase/app'
import { getStorage } from '@firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCBDIT62IghFMdxNhHaw3gRrStNw8_4Yk0",
    authDomain: "zar-paulo-os.firebaseapp.com",
    projectId: "zar-paulo-os",
    storageBucket: "zar-paulo-os.appspot.com",
    messagingSenderId: "973303022076",
    appId: "1:973303022076:web:b633fbe5244b4672477279"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


export {
  storage
};
